<template>
  <div class="footer-wrap">
    <div class="footer-content">
      ❤Love you as love life, love life as love you❤
    </div>
    <div style="text-align: center;margin: 20px;">
      <a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备16008690号</a>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .footer-content {
    padding-top: 15px;
    text-align: center;
    color: #909399;
    font-size: 16px;
  }
</style>
